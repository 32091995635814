import Utils from '../features/utils'
import { IsHTMLInputElement } from '../features/dom_utils'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'

export interface ArchivingController
  extends ExposeStimulus<typeof ArchivingController> {}
export class ArchivingController extends StimulusControllerBase {
  static targets = ['item', 'folder']
  getSelectedItems(selector: string) {
    const selectedItems = Array.from(document.querySelectorAll(selector))
      .filter(
        (checkbox) =>
          IsHTMLInputElement(checkbox) &&
          checkbox.checked &&
          checkbox.value !== 'on'
      )
      .map((checkbox) => IsHTMLInputElement(checkbox) && checkbox.value)
    return selectedItems
  }

  async activate() {
    const selectedFolderIds = this.hasFolderTarget
      ? [this.folderTarget.id]
      : this.getSelectedItems('.folders-item-check-input')
    const selectedTemplateIds = this.getSelectedItems(
      '.templates-item-check-input'
    )
    const selectedCustomClauseIds = this.getSelectedItems(
      '.custom-clauses-item-check-input'
    )
    const selectedItemIds = this.hasItemTarget
      ? [this.itemTarget.id]
      : selectedTemplateIds.concat(selectedCustomClauseIds)
    const url = '/templates/bulk_action'
    const requestData = {
      bulk_action: 'activate',
      permanent_delete: false,
      template_ids: selectedItemIds,
      folder_ids: selectedFolderIds
    }
    try {
      const response = await Utils.axios.post(url, requestData)
      if (response.status.toString().startsWith('2')) {
        avv_toast({
          message: localizeText('templates.archive.notice.all_items_activated'),
          type: 'default'
        })
        setTimeout(() => window.location.reload(), 300)
      } else {
        avv_toast({
          message: localizeText('templates.archive.notice.not_authorized'),
          type: 'error'
        })
      }
    } catch (e: any) {
      avv_toast({
        message: e.message,
        type: 'error'
      })
    }
  }

  async archiveMultipleFolders() {
    const selectedItemIds = sidebarStore.state.itemIdsToArchive
    const selectedFolderIds = sidebarStore.state.folderIdsToArchive
    let snackMessage = ''
    let error = false
    const url = '/templates/bulk_action'
    const requestData = {
      bulk_action: 'archive',
      permanent_delete: false,
      template_ids: selectedItemIds,
      folder_ids: selectedFolderIds
    }
    try {
      const response = await Utils.axios.post(url, requestData)

      window.sidebarStore.commit('TOGGLE_ARCHIVE_MODAL_OPEN')
      if (response.status.toString().startsWith('2')) {
        const { non_empty_folders, not_authorized, notice } = response.data
        window.sidebarStore.commit('SET_FOLDERS_TO_ARCHIVE', {
          folders: non_empty_folders
        })
        if (non_empty_folders) {
          window.sidebarStore.commit('TOGGLE_CONFIRM_ARCHIVE_MODAL_OPEN')
          return
        } else if (not_authorized) {
          snackMessage = notice
          error = true
        } else {
          snackMessage = notice
        }
        window.avv_toast({
          message: snackMessage,
          type: error ? 'error' : 'default'
        })
        if (!non_empty_folders && !not_authorized)
          setTimeout(() => window.location.reload(), 300)
      }
    } catch (e: any) {
      avv_toast({
        message: e.message,
        type: 'error'
      })
    }
  }

  showModal() {
    const archiveFromSidebar = this.hasFolderTarget || this.hasItemTarget
    const selectedFolderIds = this.hasFolderTarget
      ? [this.folderTarget.id]
      : archiveFromSidebar
        ? []
        : this.getSelectedItems('.folders-item-check-input')
    const selectedTemplateIds = this.getSelectedItems(
      '.templates-item-check-input'
    )
    const selectedCustomClauseIds = this.getSelectedItems(
      '.custom-clauses-item-check-input'
    )
    const selectedItemIds = this.hasItemTarget
      ? [this.itemTarget.id]
      : archiveFromSidebar
        ? []
        : selectedTemplateIds.concat(selectedCustomClauseIds)

    if (!selectedFolderIds.length && !selectedItemIds.length) return

    window.sidebarStore.commit('SET_ARCHIVE_ITEMS', {
      folderIds: selectedFolderIds,
      itemIds: selectedItemIds
    })
    window.sidebarStore.commit('TOGGLE_ARCHIVE_MODAL_OPEN')
  }

  showModalFromSidebar() {
    window.sidebarStore.commit('SET_ARCHIVE_FROM_SIDEBAR', true)
    this.showModal()
  }
}
export default ArchivingController
